.badge {
    width: fit-content;
    height: fit-content;
    display: inline-block;
    line-height: 1em;
    padding: 0.4em 0.5em;
    color: #FFFFFF;
    border-radius: 0.5em;
    font-weight: bold;
}

.badge-pill {
    border-radius: 1em;
}

.badge-brand {
    background-color: #007FA3;
}

.badge-primary{
    background-color: #9E007E;
}

.badge-secondary{
    background-color: #ffc107;
}

.badge-success{
    background-color: #198754;
}

.badge-info{
    background-color: #1977D4;
}

.badge-warning{
    background-color: #ffc107;
}

.badge-danger{
    background-color: #DB0020;
}