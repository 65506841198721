$pearson-charcol: #252525;
$pearson-digital-blue: #047a9c;
$pearson-medium-grey: #6a7070;
$pearson-white-grey: #f5f5f5;
$pearson-blue: #007fa3;
$pearson-ink-black: #000000;
$pearson-chalk-white: #ffffff;
$pearson-juicy-orange: #ea7600;
$pearson-sunshine-yellow: #ffb81c;
$pearson-strawberry-red: #db0020;
$pearson-grass-green: #008638;
$pearson-fresh-green: #84bd00;
$pearson-focus: #1977d4;
$pearson-dark-blue: #005a70;

$pearson-warning: #ea7600;

html,
body {
  background-color: $pearson-white-grey;
  height: 100vh;
}

body.wait,
body.wait * {
  cursor: wait !important;
}

// #root {
//   height: 100%;
//   display: flex;
//   flex-direction: column;
//   .content {
//     flex: 1 0 auto;
//     margin-bottom: 50px;
//   }
.footer {
  flex-shrink: 0;
  // height: 100px;
  padding-top: 1em;
  width: 100%;
  // background-color: $pearson-dark-blue;
  // ul li {
  //   border-right-color: $pearson-chalk-white;
  // }
}

// }
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.header {
  width:100%;
  height: 64px;
  display: inline-block;
}

.headerIcons {
  position: absolute;
  width: 85%;
}

.helpIconWrapper {
  position: relative;
  float: right;
}

.toolTipWrapper {
  float: right;
  width: 100%;
  z-index: 999;
}

.toolTipWrapper2 {
  float: right;
  z-index: 999;
}

.toolTipText {
  position: relative;
  float: right;
  // margin-top: -4.5%;
  margin-top: -125%;
  z-index: 999;
  width: 47px;
  height: 47px;
  margin-right: 100%;
  // border:1px solid red;
}

.tooltipname {
  white-space: pre-wrap;
  /* CSS3 */
  white-space: -moz-pre-wrap;
  /* Firefox */
  white-space: -pre-wrap;
  /* Opera <7 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* IE */
}

div[id^=tippy-] {
  text-align: center;
}

#main {
  margin: 22px auto;
  min-height: 70.5vh;
}

.dashboardIcon {
  float: left;
  margin-top: 10px;
}

.dasboard-title {
  padding-left: 48px;
}

.margin0 {
  margin: 0px;
}

.userCount {
  padding-top: 14px;
  position: absolute;
}

.userIconSVGPosition {
  float: left;
  margin: 16px 10px;
}

.statusLabel {
  float: left;
  width: 75%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.learnersmenu {
  position: absolute;
  right: 3.5%;
  top: 20%;
}
.statusText {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  padding-left: 10px;
  border-bottom: 2px solid #fff;
}
button.btn-close.btn-close:after {
  width: 10px;
  height: 10px;
  position: relative;
  content: "\d7";
  font-size: 18px;
  color: #4d4d4d;
  font-weight: bold;
  top: -1px;
  left: 0px;
  z-index: 9999;
}
.refNumber {
  padding: 0px 5px 0px 0px;
  width: 20%;
  float: left;
}

.spacing {
  float: left;
  width: 10px;
}

.moreIcon {
  position: absolute;
  float: right;
  right: 0;
  top: 13px;
}

.moreIconTodo {
  @extend .moreIcon;
  fill: $pearson-medium-grey;
}

.card-height {
  card {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.pageHeaderContainer {
  padding-left: 13px;
}

.card-title {
  font-size: 17px;
}

.card-value {
  font-weight: bold;
}

.card {
  padding: 9px 16px;
}

.requestCard {
  margin: -11px 0px;
}

.logoText {
  margin: 5px 10px 5px 8px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-family: auto;
  padding-top: 10px;
}

.logoTextSize {
  width: 95px;
}

.appTextName {
  color: $pearson-medium-grey;
  font-size: 18px;
  padding-top: 4px;
}

.search-bar-btn {
  float: right;
  margin-top: -33px;
  margin-right: 6px;
  border: 0px;
  background: #fff;
}

.topPad10 {
  padding-top: 10px;
}

.gr-meta-without-color {
  font-size: 12px;
  line-height: 12px;
}

.backToDashBoardLink {
  cursor: pointer;
  font-size: 15px;
  position: relative;
  top: -4px;
  color: #53a3bb;
}

.backToViewLearnersLink {
  cursor: pointer;
  font-size: 15px;
  position: relative;
  top: -4px;
  color: #006A93;
}

.semi-bold-650 {
  font-weight: 650;
}

.semi-bold-500 {
  font-weight: 500;
}

.semi-bold-700 {
  font-weight: 700;
}

.gr-neutral-med-none {
  color: #6a7070;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.infotooltiptext {
  width: 391px !important;
  left: -704% !important;
  padding: 10px !important;
  text-align: left !important;
  line-height: 16px;
  font-size: 14px;
}

.returntooltiptext {
  width: 240px !important;
  left: -300% !important;
  padding: 10px !important;
  text-align: left !important;
  line-height: 22px;
  font-size: 14px;
}

.tooltipLearnerStatus {
  margin-left: -248px !important;
  text-align: left !important;
  padding: 10px !important;
}

.singleCenterHeader {
  width: 87%;
  height: 50px;
  float: left;
  background: #1E496C;
  color: #fff;
  padding-top: 13px;
}

.singlecardLeftContent {
  width: 80%;
  float: left;
  padding-left: 10px;
}

.singlecardRighttContent {
  float: right;
  padding-right: 10px;
}

.singleCenterCardWrapper {
  float: left;
  width: 100%;
  margin-top: 15px;
}

.paginationWrapper {
  margin-top: 35px;
  float: right;
}

.allLearnersFilter {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  width: auto;
  // min-width: 100px;
  // max-width: 120px;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  color: #000;
  float: left;
  margin-left: 10px;
  background-color: #dedededb;
  cursor: pointer;
}

.active {
  background: #fff;
}

.disabled {
  cursor: no-drop !important;
  color: darkgray !important;
}

.dobLabel {
  float: left;
  // width: 75%;
  text-align: right;
}

.viewLearnerStatusDropdown {

  float: left;
  width: 49%;
  margin-top: -10px;
}

.gr-select:disabled {
  color: #6a7070 !important;
  cursor: default;
}

.notSubmitWrapper {
  background-color: red;
  color: #fff;
  font-weight: bold;
  font-size: 13px;
}

.statusWrapper {
  background-color: #D9E6F1;
  color: #6a7070;
  font-size: 13px;
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

.isDisabled:hover {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;

}

.returnimg {
  padding-top: 4px
}

.tooltiptextFile {
  width: 300px !important;
  left: 35% !important;
  padding: 10px !important;
}

.tooltipRejectedStatus {
  margin-left: -424% !important;
  text-align: left !important;
  padding: 10px !important;
  width: 250px !important;
  line-height: 20px;
}

.tooltipRejectedStatusAM {
  margin-left: 264% !important;
  text-align: left !important;
  padding: 10px !important;
  width: 290px !important;
  line-height: 20px;
}

.helpLink {
  cursor: pointer;
}

.dlwtSetup
{
  margin-left: 20px;
  cursor: pointer;
}

.filter-card-click-span {
  display: block;
  width: 200px;
  cursor: pointer;
}

.filter-card-active {
  border: none;
  height: 3px;
  /* Set the hr color */
  color: #51A7A5;
  /* old IE */
  background-color: #51A7A5;
  /* Modern Browsers */
}

.manage-learners-btn-holder {
  float: left;
  margin-left: -13px;
}

.manage-learners-btn-holder Button {
  height: 50px;
  padding-bottom: 10px;
}

.saveLrBut {
  float: right;
  min-width: 110px;
  font-weight: bold;
  margin-right: 15%;
}

.saveReqBut {
  height: 51px;
  margin-left: 0px;
}

.disp-none {
  display: none;
}

.disp-inline-block {
  display: inline-block;
}

.disp-block {
  display: block;
}

.view-learners-pearson-icon {
  width: 16px;
  margin-right: 8px;
  padding: 0px 0px;
  text-align: center;
  background-color: #895B9A;
  // background-color: #007FA3;
  color: #fff;
  font-family: 'Open Sans';
  display: inline-block;
  font-size: 10px;
  // vertical-align: middle;
  line-height: 16px;
}

.view-learners-system-icon {
  width: 16px;
  margin-right: 8px;
  padding: 0px 0px;
  text-align: center;
  background-color: #cfde07;
  color: #fff;
  font-family: 'Open Sans';
  display: inline-block;
  font-size: 10px;
  // vertical-align: middle;
  line-height: 16px;
}

.alert-msg-1 {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.78pc;
}

.alert-msg-1 ul {
  margin-top: 0;
}

.alert-msg-1 div.sub-heading-3 {
  margin-top: 10px;
}

.alert-msg-1 ul li {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.75pc;
}

.alert-msg-1 p {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 0.75pc;
}

.scroller {
  // height: 340px;
  width: 124%;
  display: block;
  overflow-y: scroll;
}

.scroller::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}

.scroller::-webkit-scrollbar-thumb {
  background: #ccc;
}

.isSystemselected {
  padding: 1px 7px;
  background: #895B9A;
  margin-right: 7px;
  color: #fff;
  font-size: 15px;
  font-family: 'Open Sans';
  float: left;

}

.internalUserHeadingText {
  min-height: 41px;
  float: left;
  width: 100%;
}

.disabled-filter {
  // color: darkgray;
  // cursor: no-drop;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  width: auto;
  min-width: 90px;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
  color: darkgray;
  float: left;
  margin-left: 10px;
  background-color: #dedededb;
  cursor: context-menu;
}

.gr-theme-four-light-bg {
  width: 109px;
}

.scroll-top-middle-float-loader {
  float: left;
  position: absolute;
  left: 50%;
  z-index: 999;
}

div#shared-file-learners pearson-alert {
  max-width: 510px;
}

.badge-admin-position{
  position: absolute;
  right: 8.5%;
  top: 36%;
}

.view-learner-returned-warning-msg {
  font-size: 14px;
  border: 2px dashed goldenrod;
  padding: 8px;
  margin-right: 20px;
  background: lightgoldenrodyellow;
}